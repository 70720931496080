import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, RouteComponentProps } from 'react-router-dom'
import FroalaBaseEditor from 'froala-editor'
import FroalaEditor from 'react-froala-wysiwyg'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import { MenuItem, Paper, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import Container from '@material-ui/core/Container/Container'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import CircularProgress from '@material-ui/core/CircularProgress'
import styles from './AddEditQuestionAndAnswer.module.scss'
import http_client from '../../../misc/HttpClient'

import 'froala-editor/js/plugins/code_view.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/js/plugins/video.min.js'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/third_party/font_awesome.min.js'
import 'froala-editor/js/third_party/spell_checker.min.js'

export const AddEditQuestionAnswer: React.FC<RouteComponentProps<{
    QuestionsAndAnswerID: string
}>> = RouterProps => {
    const history = useHistory()
    const [loading, setLoading] = useState(0)
    const [HTMLValue, setHTMLValue] = useState([])
    const [tags, setTags] = useState([])
    const [froalaReady, setFroalaReady] = useState(false)
    const [froalaInitControls, setFroalaInitControls] = useState(null)
    const [insertTag, setInsertTag] = useState(null)
    const [insertImage, setInsertImage] = useState(null)
    const [previewing, setPreviewing] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const [values, setValues] = useState({
        question_and_answers_id: null,
        question: '',
        status: 'Unpublished',
        first_published: 'Not published',
        last_edited: 'Not edited',
        tags: [],
    })

    useEffect(() => {
        if (insertTag != null) {
            froalaInitControls.getEditor().html.insert(insertTag)
            setInsertTag(null)
        }
        if (insertImage != null) {
            froalaInitControls
                .getEditor()
                .image.insert(
                    insertImage,
                    null,
                    null,
                    froalaInitControls.getEditor().image.get()
                )
            froalaInitControls.getEditor().popups.hideAll()
            setInsertImage(null)
        }
    }, [insertTag, insertImage])

    const handleManualController = initControls => {
        initControls.initialize()
        setFroalaInitControls(initControls)
    }

    const handleHTMLChange = model => {
        setHTMLValue(model)
    }

    const handleInputChange = e => {
        const { name, value } = e.target
        setValues({ ...values, [name]: value })
    }

    const handleACChange = (e, b) => {
        setValues({ ...values, tags: b })
    }

    const handleSubmit = e => {
        e.preventDefault()
        let action = 'add'

        const data: {
            Question: string
            Answer: string
            Status: string
            Tags: any
            QuestionsAndAnswerID?: string
        } = {
            Question: values.question,
            Answer: HTMLValue.toString(),
            Status: values.status,
            Tags: values.tags,
        }

        if (values.question_and_answers_id !== null) {
            action = `${values.question_and_answers_id}/update`
            data.QuestionsAndAnswerID = values.question_and_answers_id.toString()
        }

        http_client
            .post(`admin/question_answer/${action}`, data, {}, [
                { loadingFunction: setLoading, name: 'loading' },
            ])
            .then(res => {
                setHTMLValue(res.QuestionAndAnswer.Answer)
                setValues({
                    question_and_answers_id:
                        res.QuestionAndAnswer.QuestionsAndAnswerID,
                    question: res.QuestionAndAnswer.Question,
                    status: res.QuestionAndAnswer.Status,
                    first_published: `${res.QuestionAndAnswer.CreatedTimeStamp} by ${res.QuestionAndAnswer.CreatedBy}`,
                    last_edited: `${res.QuestionAndAnswer.LastUpdatedTimeStamp} by ${res.QuestionAndAnswer.LastUpdatedBy}`,
                    tags: res.QuestionAndAnswer.tags,
                })
                history.goBack()
            })
            .catch(e => {
                if(e.errorCode === 401){
                    setErrorMessage(e.message)
                }
            })
    }

    useEffect(() => {
        if (RouterProps.location.search) {
            const token = RouterProps.location.search.replace('?token=', '')
            localStorage.setItem('token', token)

            history.replace(history.location.pathname)
            // axios.defaults.headers.common.Authorization = `Basic ${token}`
        }

        FroalaBaseEditor.DefineIcon('tags_icon', {
            NAME: 'Tags',
            template: 'text',
        })
        FroalaBaseEditor.DefineIcon('previewTextIcon', {
            NAME: 'Preview',
            template: 'text',
        })
        FroalaBaseEditor.RegisterCommand('previewButton', {
            title: 'Text',
            icon: 'previewTextIcon',
            callback: () => {
                setPreviewing(true)
            },
        })
        if (RouterProps.match.params.QuestionsAndAnswerID) {
            http_client
                .get(
                    `admin/question_answer/get/${RouterProps.match.params.QuestionsAndAnswerID}`,
                    {},
                    [{ loadingFunction: setLoading, name: 'loading' }]
                )
                .then(res => {
                    setHTMLValue(res.QuestionAndAnswer.Answer)
                    setTags(res.Tags)
                    setValues({
                        question_and_answers_id:
                            res.QuestionAndAnswer.QuestionsAndAnswerID,
                        question: res.QuestionAndAnswer.Question,
                        status: res.QuestionAndAnswer.Status,
                        first_published: `${res.QuestionAndAnswer.CreatedTimeStamp} by ${res.QuestionAndAnswer.CreatedBy}`,
                        last_edited: `${res.QuestionAndAnswer.LastUpdatedTimeStamp} by ${res.QuestionAndAnswer.LastUpdatedBy}`,
                        tags: res.QuestionAndAnswer.tags,
                    })
                    FroalaBaseEditor.RegisterCommand('my_dropdown', {
                        title: 'tags',
                        icon: 'tags_icon',
                        type: 'dropdown',
                        focus: false,
                        undo: false,
                        refreshAfterCallback: true,
                        options: res.Variables,
                        callback: (cmd, val) => {
                            setInsertTag(val)
                        },
                    })
                    setFroalaReady(true)
                })
                .catch(e => {
                    if(e.errorCode === 401){
                        setErrorMessage(e.message)
                    }
                })
        } else {
            http_client
                .get(`admin/tags/get`, {}, [
                    { loadingFunction: setLoading, name: 'loading' },
                ])
                .then(res => {
                    setTags(res.Tags)
                    FroalaBaseEditor.RegisterCommand('my_dropdown', {
                        title: 'tags',
                        icon: 'tags_icon',
                        type: 'dropdown',
                        focus: false,
                        undo: false,
                        refreshAfterCallback: true,
                        options: res.Variables,
                        callback: (cmd, val) => {
                            setInsertTag(val)
                        },
                    })
                    setFroalaReady(true)
                })
                .catch(e => {
                    if(e.errorCode === 401){
                        setErrorMessage(e.message)
                    }
                })
        }
    }, [])

    const froala_config = {
        events: {
            'image.beforeUpload': files => {
                if (files.length) {
                    // Create a File Reader.
                    const reader = new FileReader()
                    // Set the reader to insert images when they are loaded.
                    reader.onload = e => {
                        setInsertImage(e.target.result)
                    }
                    // Read image as base64.
                    reader.readAsDataURL(files[0])
                }
                return false
            },
        },
        toolbarButtons: [
            'bold',
            'italic',
            'underline',
            'strikeThrough',
            'subscript',
            'superscript',
            '|',
            'fontFamily',
            'fontSize',
            'color',
            'inlineStyle',
            'paragraphStyle',
            '|',
            'paragraphFormat',
            'align',
            'formatOL',
            'formatUL',
            'outdent',
            'indent',
            'quote',
            'check',
            '|',
            'insertLink',
            'insertImage',
            'insertVideo',
            'embedly',
            'insertFile',
            'insertTable',
            '|',
            'emoticons',
            'specialCharacters',
            'insertHR',
            'selectAll',
            'clearFormatting',
            '|',
            'spellChecker',
            'help',
            'html',
            '|',
            'undo',
            'redo',
            'my_dropdown',
            'previewButton',
        ],
    }

    let loading_screen = null
    if (loading > 0) {
        loading_screen = (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '500px' }}
            >
                <Grid item xs={3}>
                    <CircularProgress />
                </Grid>
                <Grid item xs={12}>
                    <h2>Loading Please Wait</h2>
                </Grid>
            </Grid>
        )
    }
    let full_response = <Container maxWidth="md">
        <Paper>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '500px' }}
            >
                <Grid item xs={12}>
                    <h2>{errorMessage}</h2>
                </Grid>
            </Grid>
        </Paper>
    </Container>
    if (errorMessage === null) {
        full_response = (
            <Fragment>
                <Container maxWidth="md">
                    <Paper className={styles.paper}>
                        {loading_screen}
                        <form
                            noValidate
                            autoComplete="off"
                            style={loading > 0 ? { display: 'none' } : {}}
                            onSubmit={handleSubmit}
                        >
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id="question"
                                        name="question"
                                        label="Question"
                                        variant="outlined"
                                        value={values.question}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {froalaReady ? (
                                        <Fragment>
                                            <div
                                                style={{
                                                    display: previewing
                                                        ? 'none'
                                                        : 'block',
                                                }}
                                            >
                                                <FroalaEditor
                                                    tag="textarea"
                                                    model={HTMLValue}
                                                    config={froala_config}
                                                    onModelChange={
                                                        handleHTMLChange
                                                    }
                                                    onManualControllerReady={
                                                        handleManualController
                                                    }
                                                />
                                            </div>
                                            <Fragment>
                                                <Paper
                                                    style={{
                                                        display: previewing
                                                            ? 'block'
                                                            : 'none',
                                                    }}
                                                >
                                                    <Button
                                                        color="primary"
                                                        onClick={e => {
                                                            setPreviewing(false)
                                                        }}
                                                    >
                                                        Back
                                                    </Button>
                                                    <h2
                                                        style={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        {values.question}
                                                    </h2>
                                                    <div
                                                        style={{
                                                            padding: '15px',
                                                        }}
                                                    >
                                                        <FroalaEditorView
                                                            model={HTMLValue}
                                                        />
                                                    </div>
                                                </Paper>
                                            </Fragment>
                                            {/* <FroalaEditorView */}
                                            {/*    model={HTMLValue} */}
                                            {/* /> */}
                                        </Fragment>
                                    ) : null}
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        id="status"
                                        name="status"
                                        select
                                        label="Status"
                                        value={values.status}
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        fullWidth
                                    >
                                        <MenuItem
                                            key="Unpublished"
                                            value="Unpublished"
                                        >
                                            Unpublished
                                        </MenuItem>
                                        <MenuItem
                                            key="Published"
                                            value="Published"
                                        >
                                            Published
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        disabled
                                        id="first_published"
                                        name="first_published"
                                        label="First Published"
                                        variant="outlined"
                                        value={values.first_published}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        disabled
                                        id="last_edited"
                                        name="last_edited"
                                        label="Last Edited"
                                        variant="outlined"
                                        value={values.last_edited}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Autocomplete
                                        multiple
                                        id="tags"
                                        options={tags}
                                        getOptionLabel={option => option.Tag}
                                        filterSelectedOptions
                                        onChange={handleACChange}
                                        value={values.tags}
                                        autoHighlight
                                        autoSelect
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                name="tags"
                                                label="Topics"
                                                placeholder="Topics"
                                                fullWidth
                                                // onChange={handleInputChange}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="flex-start"
                                    spacing={2}
                                >
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="large"
                                            onClick={() => {
                                                history.goBack()
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            startIcon={<SaveIcon />}
                                            type="submit"
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Container>
            </Fragment>
        )
    }
    return full_response
}
export default AddEditQuestionAnswer
