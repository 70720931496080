import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'formdata-polyfill'
import 'es7-object-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './store'

import './index.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
// import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css'
import 'typeface-roboto'

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js'

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css'

import App from './App'

// if (!Object.entries)
//     Object.entries = function (obj) {
//         var ownProps = Object.keys(obj),
//             i = ownProps.length,
//             resArray = new Array(i); // preallocate the Array
//         while (i--)
//             resArray[i] = [ownProps[i], obj[ownProps[i]]];
//
//         return resArray;
//     };

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
