import React, { Fragment } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import CssBaseline from '@material-ui/core/CssBaseline'
import { QuestionList } from './pages/admin/QuestionList/QuestionList'
import { AddEditQuestionAnswer } from './pages/admin/AddEditQuestionAnswer/AddEditQuestionAnswer'
import {Search} from "./pages/client/Search/Search";



const App: React.FC = () => {
    return (
        <BrowserRouter>
            <CssBaseline />
            <Switch>
                <Fragment>
                    <Route path="/" component={Search} exact />
                    <Route
                        path="/admin/question-answer-list"
                        component={QuestionList}
                    />
                    <Route
                        path="/admin/add-edit-question/:QuestionsAndAnswerID?"
                        component={AddEditQuestionAnswer}
                    />
                </Fragment>
            </Switch>
        </BrowserRouter>
    )
}

export default App
