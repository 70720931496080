import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, RouteComponentProps } from 'react-router-dom'
import axios, { AxiosRequestConfig } from 'axios'
import Container from '@material-ui/core/Container/Container'
import {
    Card,
    CardContent,
    Chip,
    Divider,
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    Typography,
    TextField,
    Button,
    Select,
    MenuItem,
} from '@material-ui/core'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import SearchIcon from '@material-ui/icons/Search'
import { Autocomplete } from '@material-ui/lab'
import FroalaBaseEditor from 'froala-editor'
import FroalaEditor from 'react-froala-wysiwyg'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import styles from './Search.module.scss'
import http_client from '../../../misc/HttpClient'

export const Search: React.FC<RouteComponentProps<{
    location: string
}>> = RouterProps => {
    const history = useHistory()

    const [searchQuery, setSearchQuery] = useState('')
    const [loading, setLoading] = useState(0)
    const [tags, setTags] = useState([])
    const [answers, setAnswers] = useState([])
    const [selectedTag, setSelectedTag] = useState({ TagID: -1, Tag: '' })
    const [selectedTagID, setSelectedTagID] = useState(null)

    const [hasSearched, setHasSearched] = useState(false)
    const [viewingAnswerID, setViewingAnswerID] = useState(-1)
    const [errorMessage, setErrorMessage] = useState(null)

    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current!.offsetWidth);
    }, []);

    const handleSearchChange = e => {
        const { name, value } = e.target
        setSearchQuery(value)
    }

    const handleSearchSubmit = e => {
        e.preventDefault()
        setViewingAnswerID(-1)

        http_client
            .get(`client/question_answer/search?query=${searchQuery}`, {}, [
                { loadingFunction: setLoading, name: 'loading' },
            ])
            .then(res => {
                setAnswers(res.Answers)
                setHasSearched(true)
            })
            .catch(e => {
                if (e.errorCode === 401) {
                    setErrorMessage(e.message)
                }
            })
    }

    const handleTagSubmit = e => {
        setViewingAnswerID(-1)
        if(e !== null){
            e.preventDefault()
        }

        if(selectedTagID === null){
            return false
        }
        http_client
            .get(
                `client/question_answer/filter?tag_id=${selectedTagID}`,
                {},
                [{ loadingFunction: setLoading, name: 'loading' }]
            )
            .then(res => {
                setAnswers(res.Answers)
                setHasSearched(true)
            })
            .catch(e => {
                if (e.errorCode === 401) {
                    setErrorMessage(e.message)
                }
            })
    }



    const handleACChange = (e, b) => {
        if(b !== selectedTag){
            setSelectedTag(b)
            handleTagSubmit(e)
        }
    }

    const handleSelectChange = (e) => {
        if(e.target.value as number !== selectedTagID){
            setSelectedTagID(e.target.value as number)
        }
    }
    useEffect(() => {
        handleTagSubmit(null)
    }, [selectedTagID])

    useEffect(() => {
        if (RouterProps.location.search) {
            const token = RouterProps.location.search.replace('?token=', '')
            localStorage.setItem('token', token)

            history.replace(history.location.pathname)
            // axios.defaults.headers.common.Authorization = `Basic ${token}`
        }

        http_client
            .get(`client/tags/get`)
            .then(res => {
                setTags(res.Tags)
            })
            .catch(e => {
                if (e.errorCode === 401) {
                    setErrorMessage(e.message)
                }
            })
    }, [])

    let loading_screen = null
    if (loading > 0) {
        loading_screen = (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '500px' }}
            >
                <Grid item xs={3}>
                    <CircularProgress />
                </Grid>
                <Grid item xs={3}>
                    <h2>Loading Please Wait</h2>
                </Grid>
            </Grid>
        )
    }

    let question_or_answer_panel = null

    if (loading === 0) {
        if (viewingAnswerID !== -1) {
            const active_answer = answers.find(
                e => e.QuestionsAndAnswerID === viewingAnswerID
            )
            question_or_answer_panel = (
                <Fragment>
                    <Button
                        color="primary"
                        onClick={e => {
                            setViewingAnswerID(-1)
                        }}
                    >
                        Back
                    </Button>
                    <h2 style={{ textAlign: 'center' }}>
                        {active_answer.Question}
                    </h2>
                    <div style={{ padding: '15px' }}>
                        <FroalaEditorView model={active_answer.Answer} />
                    </div>
                </Fragment>
            )
        } else if (answers.length) {
            question_or_answer_panel = answers.map((answer, i) => {
                return (
                    <Fragment key={answer.QuestionsAndAnswerID}>
                        <ListItem
                            button
                            alignItems="flex-start"
                            onClick={e => {
                                setViewingAnswerID(answer.QuestionsAndAnswerID)
                            }}
                        >
                            <ListItemText
                                primary={answer.Question}
                                secondary={answer.Tags.join(', ')}
                            />
                        </ListItem>
                        <Divider variant="inset" />
                    </Fragment>
                )
            })
        } else if (hasSearched) {
            question_or_answer_panel = (
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '300px' }}
                >
                    <Grid item xs={3}>
                        <h2>No Results</h2>
                    </Grid>
                </Grid>
            )
        }
    }
    let full_response = (
        <Container maxWidth="md">
            <Paper>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '500px' }}
                >
                    <Grid item xs={12}>
                        <h2>{errorMessage}</h2>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
    if (errorMessage === null) {
        full_response = (
            <Fragment>
                <Container maxWidth="md">
                    <Paper className={styles.paper}>
                        <form
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSearchSubmit}
                        >
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-adornment-password">
                                    Search by keywords
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type="text"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    fullWidth
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="search"
                                                onClick={handleSearchSubmit}
                                                edge="end"
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={70}
                                />
                            </FormControl>
                        </form>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                            style={{ minHeight: '30px' }}
                        >
                            <Grid item xs={3}>
                                <div>OR</div>
                            </Grid>
                        </Grid>
                        <form
                            noValidate
                            autoComplete="off"
                            onSubmit={handleTagSubmit}
                        >
                            {/*<Autocomplete*/}
                            {/*    id="tags"*/}
                            {/*    options={tags}*/}
                            {/*    getOptionLabel={option => option.Tag}*/}
                            {/*    filterSelectedOptions*/}
                            {/*    onChange={handleACChange}*/}
                            {/*    value={selectedTag}*/}
                            {/*    autoHighlight*/}
                            {/*    autoSelect*/}
                            {/*    renderInput={params => (*/}
                            {/*        <TextField*/}
                            {/*            {...params}*/}
                            {/*            variant="outlined"*/}
                            {/*            name="tags"*/}
                            {/*            label="Search by topic"*/}
                            {/*            placeholder="Topics"*/}
                            {/*            fullWidth*/}
                            {/*            // onChange={e => {*/}
                            {/*            //     setTags([e.target.value])*/}
                            {/*            // }}*/}
                            {/*        />*/}
                            {/*    )}*/}
                            {/*/>*/}
                            <FormControl
                                variant="outlined"
                                style={{width: '100%'}}
                            >
                                <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
                                    Search by topic
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={selectedTagID}
                                    onChange={handleSelectChange}
                                    labelWidth={labelWidth}
                                >
                                    {tags.map((tag, i) => {
                                        return (
                                            <MenuItem value={tag.TagID}>
                                                <em>{tag.Tag}</em>
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </form>
                    </Paper>

                    <Paper>
                        {loading_screen}
                        {question_or_answer_panel}
                    </Paper>
                </Container>
            </Fragment>
        )
    }
    return full_response
}
