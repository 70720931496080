import axios, { AxiosRequestConfig } from 'axios'

export class HttpClient {
    loading_counters = {}

    server: string = 'https://faq.thinksmartsoftware.com/faq_backend/'

    get = async (url, options?, loading_array?) => {
        if (typeof loading_array !== 'undefined') {
            this.incLoadingCounters(loading_array)
        }

        if (typeof options === 'undefined') {
            options = {}
        }
        options.method = 'GET'
        if (localStorage.getItem('token')) {
            options.headers = {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }
        const rawResponse = await fetch(this.server + url, options)
        if (typeof loading_array !== 'undefined') {
            this.decLoadingCounters(loading_array)
        }
        const json_response = rawResponse.json()

        return json_response.then((res) => {
            switch(rawResponse.status){
                case 200:
                    return Promise.resolve(res)
                case 400:
                    alert(res.Message)
                    return Promise.reject({errorCode: rawResponse.status, message: res.Message})
                case 401:
                    return Promise.reject({errorCode: rawResponse.status, message: res.Message})
            }
        })
    }

    post = async (url, params?, options?, loading_array?) => {
        if (typeof loading_array !== 'undefined') {
            this.incLoadingCounters(loading_array)
        }
        if (typeof options === 'undefined') {
            options = {}
        }
        if (typeof params !== 'undefined') {
            options.body = JSON.stringify(params)
        }
        options.method = 'POST'
        if (localStorage.getItem('token')) {
            options.headers = {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }
        options = {
            ...options,
            location: {
                ...{
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            },
        }

        const rawResponse = await fetch(this.server + url, options)

        if (typeof loading_array !== 'undefined') {
            this.decLoadingCounters(loading_array)
        }
        const json_response = rawResponse.json()
        return json_response.then((res) => {
            return json_response.then((res) => {
                switch(rawResponse.status){
                    case 200:
                        return Promise.resolve(res)
                    case 400:
                        alert(res.Message)
                        return Promise.reject({errorCode: rawResponse.status, message: res.Message})
                    case 401:
                        return Promise.reject({errorCode: rawResponse.status, message: res.Message})
                }
            })
        })
    }

    incLoadingCounters = (loading_array) => {
        loading_array.forEach(loading_function => {
            if (
                Object.prototype.hasOwnProperty.call(
                    this.loading_counters,
                    loading_function.name
                )
            ) {
                this.loading_counters[loading_function.name] += 1
            } else {
                this.loading_counters[loading_function.name] = 1
            }
            loading_function.loadingFunction(
                this.loading_counters[loading_function.name]
            )
        })
    }

    decLoadingCounters = (loading_array) => {
        loading_array.forEach(loading_function => {
            if (
                Object.prototype.hasOwnProperty.call(
                    this.loading_counters,
                    loading_function.name
                ) &&
                this.loading_counters[loading_function.name] > 0
            ) {
                this.loading_counters[loading_function.name] -= 1
            } else {
                this.loading_counters[loading_function.name] = 0
            }
            loading_function.loadingFunction(
                this.loading_counters[loading_function.name]
            )
        })
    }
}
const http_client = new HttpClient()
export default http_client
