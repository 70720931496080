import React, { Fragment, useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
// import QuestionManager from '../../components/question_manager/QuestionManager'
import axios from 'axios'
import MaterialTable from 'material-table'
import Container from '@material-ui/core/Container/Container'
import { Paper } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import styles from './QuestionList.module.scss'
import http_client from '../../../misc/HttpClient'

export const QuestionList: React.FC<RouteComponentProps<{
    location: string
}>> = RouterProps => {
    const history = useHistory()
    const [questionList, setQuestionList] = useState([])
    const [tagsList, settagsList] = useState([])
    const [errorMessage, setErrorMessage] = useState(null)

    const question_columns = [
        { title: 'Question', field: 'Question' },
        { title: 'Status', field: 'Status' },
        { title: 'Topics', field: 'Tags' },
    ]

    const topics_columns = [{ title: 'Topic', field: 'Tag' }]

    useEffect(() => {
        if (RouterProps.location.search) {
            const token = RouterProps.location.search.replace('?token=', '')
            localStorage.setItem('token', token)

            history.replace(history.location.pathname)
            // axios.defaults.headers.common.Authorization = `Basic ${token}`
        }

        http_client
            .get(
                `admin/question_answer/get`
                // `http://localhost/faq_backend/admin/get_questions?XDEBUG_SESSION_START=dean`
            )
            .then(res => {
                setQuestionList(res.Questions)
                settagsList(res.Tags)
            })
            .catch(e => {
                if (e.errorCode === 401) {
                    setErrorMessage(e.message)
                }
            })
    }, [])

    let full_response = (
        <Container maxWidth="md">
            <Paper>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '500px' }}
                >
                    <Grid item xs={12}>
                        <h2>{errorMessage}</h2>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
    if (errorMessage === null) {
        full_response = (
            <Fragment>
                <Container maxWidth="md">
                    <div className={styles.material_table}>
                        <MaterialTable
                            title="Questions"
                            columns={question_columns}
                            data={questionList}
                            actions={[
                                {
                                    icon: 'edit',
                                    tooltip: 'Edit',
                                    onClick: (event, rowData) => {
                                        console.log(rowData)
                                        history.push(
                                            `/admin/add-edit-question/${rowData.QuestionsAndAnswerID}`
                                        )
                                    },
                                },
                                {
                                    icon: 'delete_outline',
                                    tooltip: 'Delete',
                                    onClick: (event, rowData) =>
                                        console.log(rowData),
                                },
                                {
                                    icon: 'add_box',
                                    tooltip: 'Add',
                                    isFreeAction: true,
                                    onClick: event =>
                                        history.push(
                                            `/admin/add-edit-question`
                                        ),
                                },
                            ]}
                        />
                    </div>
                    <div className={styles.material_table}>
                        <MaterialTable
                            title="Topics"
                            columns={topics_columns}
                            data={tagsList}
                            editable={{
                                onRowAdd: newData =>
                                    new Promise(resolve => {
                                        http_client
                                            .post(`admin/tag/add`, {
                                                TagName: newData.Tag,
                                            })
                                            .then(res => {
                                                settagsList(res.Tags)
                                                resolve()
                                            })
                                            .catch(e => {
                                                if (e.errorCode === 401) {
                                                    setErrorMessage(e.message)
                                                }
                                            })
                                    }),
                                onRowUpdate: (newData, oldData) =>
                                    new Promise(resolve => {
                                        http_client
                                            .post(
                                                `admin/tag/${oldData.TagID}/update`,
                                                {
                                                    TagName: newData.Tag,
                                                }
                                            )
                                            .then(res => {
                                                settagsList(res.Tags)
                                                resolve()
                                            })
                                            .catch(e => {
                                                if (e.errorCode === 401) {
                                                    setErrorMessage(e.message)
                                                }
                                            })
                                    }),
                                onRowDelete: oldData =>
                                    new Promise(resolve => {
                                        http_client
                                            .post(
                                                `admin/tag/${oldData.TagID}/delete`
                                            )
                                            .then(res => {
                                                settagsList(res.Tags)
                                                resolve()
                                            })
                                            .catch(e => {
                                                if (e.errorCode === 401) {
                                                    setErrorMessage(e.message)
                                                }
                                            })
                                    }),
                            }}
                        />
                    </div>
                </Container>
            </Fragment>
        )
    }
    return full_response
}
